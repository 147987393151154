import { createContext, useState } from "react"
import { MODES } from "../components/atoms/select/CustomerOrFundSelect/CustomerOrFundSelect"
import { DEFAULT_ASSET_LOCALE, DEFAULT_ASSET_VISUALIZATION_TYPE } from "../utils/enums"

export const PositionsContext = createContext({
  visualizationType: DEFAULT_ASSET_VISUALIZATION_TYPE,
  setVisualizationType: () => {},
  customerOrFund: null,
  setCustomerOrFund: () => {},
  locale: DEFAULT_ASSET_LOCALE,
  setLocale: () => {},
  hideEmptyClasses: true,
  setHideEmptyClasses: () => {},
  isToShowOnlyAssets: false,
  setIsToShowOnlyAssets: () => {},
  isExplodedView: false,
  setIsExplodedView: () => {},
  referenceDate: null,
  setReferenceDate: () => {},
  assetName: "",
  setAssetName: () => {},
  mode: MODES.CUSTOMER,
  setMode: () => {},
  positions: [],
  setPositions: () => {},
  datesAvailable: [],
  setDatesAvailable: () => {},
  graph: [],
  setGraph: () => {},
  customersOrFunds: [],
  setCustomersOrFunds: () => {},
  reload: 0,
  forceReload: () => {},
  isLoading: false,
  setIsLoading: () => {},
})

export const PositionsProvider = ({ children }) => {
  const [visualizationType, setVisualizationType] = useState(DEFAULT_ASSET_VISUALIZATION_TYPE)
  const [customerOrFund, setCustomerOrFund] = useState(null)
  const [locale, setLocale] = useState(DEFAULT_ASSET_LOCALE)
  const [hideEmptyClasses, setHideEmptyClasses] = useState(true)
  const [isToShowOnlyAssets, setIsToShowOnlyAssets] = useState(false)
  const [isExplodedView, setIsExplodedView] = useState(false)
  const [referenceDate, setReferenceDate] = useState(null)
  const [assetName, setAssetName] = useState("")
  const [mode, setMode] = useState(MODES.CUSTOMER)
  const [isLoading, setIsLoading] = useState(false)
  const [reload, setReload] = useState(0)
  const [positions, setPositions] = useState([])
  const [datesAvailable, setDatesAvailable] = useState([])
  const [graph, setGraph] = useState([])
  const [customersOrFunds, setCustomersOrFunds] = useState([])

  const forceReload = () => setReload(reload + 1)

  return (
    <PositionsContext.Provider
      value={{
        visualizationType,
        setVisualizationType,
        customerOrFund,
        setCustomerOrFund,
        locale,
        setLocale,
        hideEmptyClasses,
        setHideEmptyClasses,
        isExplodedView,
        setIsExplodedView,
        referenceDate,
        setReferenceDate,
        isToShowOnlyAssets,
        setIsToShowOnlyAssets,
        assetName,
        setAssetName,
        mode,
        setMode,
        isLoading,
        setIsLoading,
        reload,
        forceReload,
        positions,
        setPositions,
        datesAvailable,
        setDatesAvailable,
        graph,
        setGraph,
        customersOrFunds,
        setCustomersOrFunds,
      }}
    >
      {children}
    </PositionsContext.Provider>
  )
}
