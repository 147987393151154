import { Flex, Input } from "antd"
import { spaces } from "../../../../utils/styles"
import { useState, useEffect } from "react"

const DEBOUNCE_DELAY = 400

export const AssetNameFilter = ({ setValue, width = 300 }) => {
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    const handler = setTimeout(() => {
      setValue(searchTerm)
    }, DEBOUNCE_DELAY)

    return () => clearTimeout(handler)
  }, [searchTerm, setValue])

  const onChange = (e) => {
    setSearchTerm(e.target.value)
  }

  return (
    <Flex style={{ gap: spaces.space2, alignItems: "center" }}>
      <b style={{ textWrap: "nowrap" }}>Ativo:</b>
      <Input placeholder="Filtrar pelo nome" onChange={onChange} style={{ width }} />
    </Flex>
  )
}
