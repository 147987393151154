// ScatterPlot.js
import * as d3 from "d3"
import React, { useEffect, useRef } from "react"
import { colors } from "../../../utils/styles"

const ScatterPlot = ({ data, width, height, xAxisTitle, yAxisTitle, dotColor = colors.primary }) => {
  const svgRef = useRef()

  useEffect(() => {
    // Configurações básicas
    const margin = { top: 20, right: 30, bottom: 70, left: 70 }
    const innerWidth = width - margin.left - margin.right
    const innerHeight = height - margin.top - margin.bottom

    // Seleciona o SVG
    const svg = d3.select(svgRef.current).attr("width", width).attr("height", height)

    // Limpa o conteúdo anterior
    svg.selectAll("*").remove()

    // Cria escalas
    const xScale = d3
      .scaleLinear()
      .domain(d3.extent(data, (d) => d.x))
      .range([0, innerWidth])
      .nice()

    const yScale = d3
      .scaleLinear()
      .domain(d3.extent(data, (d) => d.y))
      .range([innerHeight, 0])
      .nice()

    // Cria um grupo de plotagem
    const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`)

    // Define os eixos com linhas de grade
    const xAxis = d3.axisBottom(xScale).ticks(10).tickSize(-innerHeight).tickPadding(10)

    const yAxis = d3.axisLeft(yScale).ticks(10).tickSize(-innerWidth).tickPadding(10)

    // Adiciona o eixo X
    g.append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(xAxis)
      .call((g) => g.select(".domain").remove())
      .call((g) => g.selectAll(".tick line").attr("stroke", "#ccc").attr("stroke-dasharray", "2,2"))
      .call((g) => g.selectAll(".tick text").attr("y", 15))

    // Adiciona o título do eixo X
    g.append("text")
      .attr("class", "x-axis-title")
      .attr("text-anchor", "middle")
      .attr("x", innerWidth / 2)
      .attr("y", innerHeight + margin.bottom - 20)
      .text(xAxisTitle)
      .attr("font-size", "16px")
      .attr("fill", "black")

    // Adiciona o eixo Y
    g.append("g")
      .call(yAxis)
      .call((g) => g.select(".domain").remove())
      .call((g) => g.selectAll(".tick line").attr("stroke", "#ccc").attr("stroke-dasharray", "2,2"))
      .call((g) => g.selectAll(".tick text").attr("x", -10))

    // Adiciona o título do eixo Y
    g.append("text")
      .attr("class", "y-axis-title")
      .attr("text-anchor", "middle")
      .attr("x", -innerHeight / 2)
      .attr("y", -margin.left + 20)
      .attr("transform", "rotate(-90)")
      .text(yAxisTitle)
      .attr("font-size", "16px")
      .attr("fill", "black")

    // Adiciona pontos (bolinhas) com a cor definida pela prop dotColor
    g.selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d.x))
      .attr("cy", (d) => yScale(d.y))
      .attr("r", 5)
      .attr("fill", dotColor) // Usando a prop dotColor

    // Adiciona rótulos com posição ajustada
    g.selectAll("text.label")
      .data(data)
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("x", (d) => xScale(d.x))
      .attr("y", (d) => {
        const yPos = yScale(d.y)
        const labelOffset = 15
        const threshold = 20

        if (yPos + labelOffset > innerHeight - threshold) {
          return yPos - labelOffset
        } else {
          return yPos + labelOffset
        }
      })
      .text((d) => d.name)
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .attr("fill", "black")
  }, [data, width, height, xAxisTitle, yAxisTitle, dotColor])

  return <svg ref={svgRef}></svg>
}

export default ScatterPlot
