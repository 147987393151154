import { Flex, Radio, Table, Tag } from "antd"
import { useMaturity } from "../../../hooks/useMaturity"
import { CURRENCY } from "../../../utils/enums"
import { borders, spaces } from "../../../utils/styles"
import { Loader } from "../../atoms/animations/Loader/Loader"
import { MoneyLabel } from "../../atoms/formatters/MoneyLabel/MoneyLabel"
import { SimpleRoundedValueLabel } from "../../atoms/formatters/SimpleRoundedValueLabel/SimpleRoundedValueLabel"
import { CustomerOrFundSelect, MODES } from "../../atoms/select/CustomerOrFundSelect/CustomerOrFundSelect"
import { formatDate } from "../../../utils/utils"
import { useViewport } from "../../../hooks/useViewport"
import { AssetNameFilter } from "../../atoms/input/AssetNameFilter/AssetNameFilter"
import { useMaturityContext } from "../../../context/MaturityContext"

export const Maturity = () => {
  const { assets, isLoading } = useMaturity()
  const { setMode, setCustomerOrFund, setAssetName, mode } = useMaturityContext()
  const { height } = useViewport()

  const columns = [
    {
      title: mode === MODES.CUSTOMER ? "Cliente" : "Fundo",
      dataIndex: "customerOrFund",
      key: "customerOrFund",
      render: (_, row) => <Tag color={row.color}>{row.customerOrFund}</Tag>,
    },
    {
      title: "Ativo",
      dataIndex: "asset",
      key: "asset",
    },
    {
      title: "Vencimento",
      dataIndex: "maturity",
      key: "maturity",
      render: (value) => formatDate(value),
    },
    {
      title: "Cotas",
      dataIndex: "quantity",
      key: "quantity",
      render: (value) => <SimpleRoundedValueLabel value={value} />,
    },
    {
      title: "Valor (R$)",
      dataIndex: "value_brl",
      key: "value_brl",
      render: (value) => <MoneyLabel value={value} currency={CURRENCY.brl} />,
    },
    {
      title: "Valor (US$)",
      dataIndex: "value_usd",
      key: "value_usd",
      render: (value) => <MoneyLabel value={value} currency={CURRENCY.usd} />,
    },
  ]

  return (
    <Flex style={{ padding: spaces.space2, height: "100%", justifyContent: "center", alignItems: "flex-start" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <Flex vertical style={{ gap: spaces.space2, alignItems: "baseline" }}>
          <Flex style={{ alignItems: "center", gap: spaces.space2 }}>
            <AssetNameFilter setValue={setAssetName} />
            <CustomerOrFundSelect mode={mode} setValue={setCustomerOrFund} />
            <Radio.Group
              value={mode}
              onChange={(e) => {
                setMode(e.target.value)
                setCustomerOrFund(null)
              }}
            >
              <Radio value={MODES.CUSTOMER}>Cliente</Radio>
              <Radio value={MODES.FUND}>Fundos TERA</Radio>
            </Radio.Group>
          </Flex>
          <Table
            columns={columns}
            dataSource={assets}
            rowClassName={(_, index) => (index % 2 === 0 ? "table-row-dark" : "")}
            style={{ border: borders.default, borderRadius: 6, width: 800, marginBottom: spaces.space2, width: "80vw" }}
            pagination={{ pageSize: height > 551 ? 11 : 5, showSizeChanger: false }}
            locale={{ emptyText: "Nenhum vencimento previsto" }}
          />
        </Flex>
      )}
    </Flex>
  )
}
