import { useEffect, useState } from "react"
import { useSupabase } from "./useSupabase"
import { getLastDateAvailable } from "../service/PositionsService"
import { tables } from "../utils/utils"
import { MODES } from "../components/atoms/select/CustomerOrFundSelect/CustomerOrFundSelect"
import { useMaturityContext } from "../context/MaturityContext"

export const useMaturity = () => {
  const [assets, setAssets] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { mode, customerOrFund, assetName } = useMaturityContext()
  const supabase = useSupabase()

  useEffect(() => {
    const fetch = async () => {
      const table = tables.FACT_ASSET_ALLOCATION
      const table_customer = tables.DIM_CUSTOMER
      const table_fund = tables.DIM_TERA_FUND
      const table_asset = tables.DIM_ASSET

      const date = await getLastDateAvailable(supabase, table)

      const baseTable = mode === MODES.CUSTOMER ? table_customer : table_fund

      const query = supabase
        .from(table)
        .select(`quantity, value_brl, value_usd, ${baseTable} (name, color), ${table_asset}!inner (name, maturity)`)
        .eq("date", date)
        .filter(`${table_asset}.maturity`, "gte", date)
        .not(`${table_asset}.maturity`, "is", null)

      if (mode === MODES.CUSTOMER) query.filter(`${table_fund}_id`, "is", null)
      if (mode === MODES.FUND) query.filter(`${table_customer}_id`, "is", null)
      if (mode === MODES.CUSTOMER && customerOrFund) query.eq(`${table_customer}_id`, customerOrFund)
      if (mode === MODES.FUND && customerOrFund) query.eq(`${table_fund}_id`, customerOrFund)
      if (assetName && assetName.length > 1) query.ilike(`${table_asset}.name`, `%${assetName}%`)
      
        const { data } = await query

      setAssets(
        data
          .map(({ quantity, value_brl, value_usd, [baseTable]: { name: customerOrFund, color }, [table_asset]: { name: asset, maturity } }) => ({
            value_brl,
            value_usd,
            customerOrFund,
            asset,
            maturity,
            quantity,
            color,
          }))
          .sort((a, b) => new Date(a.maturity) - new Date(b.maturity)),
      )
      setIsLoading(false)
    }

    fetch()
  }, [customerOrFund, mode, assetName])

  return { assets, isLoading }
}
