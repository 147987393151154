import { Tabs } from "antd"
import { ASSET_LOCALES } from "../../../utils/enums"
import { ExpectedReturn } from "./ExpectedReturn"
import { IPS } from "./IPS"
import { PortfolioModel } from "./PortfolioModel"

export const AssetAllocation = () => {
  const items = [
    {
      key: "ips-offshore",
      label: "IPS Offshore",
      children: <IPS locale={ASSET_LOCALES.offshore.value} />,
    },
    {
      key: "ips-onshore",
      label: "IPS Onshore",
      children: <IPS locale={ASSET_LOCALES.onshore.value} />,
    },
    {
      key: "carteira-modelo",
      label: "Carteira Modelo",
      children: <PortfolioModel />,
    },
    {
      key: "expected-return",
      label: "Expected Return",
      children: <ExpectedReturn />,
    },
  ]

  return <Tabs defaultActiveKey="4" items={items} />
}
