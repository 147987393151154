import { Card, Flex } from "antd"
import { spaces } from "../../utils/styles"

export const KanbanPane = ({ text, description, icon, children, width, minHeight = "83vh" }) => {
  return (
    <Card
      title={
        <Flex style={{ gap: spaces.space2, alignContent: "center", marginTop: spaces.space2, marginBottom: spaces.space1 }}>
          {icon}
          <Flex vertical>
            <b>{text}</b>
            <span style={{ fontWeight: 100, fontSize: 14 }}>{description}</span>
          </Flex>
        </Flex>
      }
      className="status-card"
      style={{ width, backgroundColor: "rgb(240, 240, 240)", minHeight }}
    >
      {children}
    </Card>
  )
}
