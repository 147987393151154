import { createContext, useState } from "react"

export const PipelineContext = createContext({
  classFilter: null,
  setClassFilter: () => {},
  nameFilter: null,
  setValue: () => {},
  selectedAsset: null,
  setSelectedAsset: () => {},
  reload: 0,
  forceReload: () => {},
})

export const PipelineProvider = ({ children }) => {
  const [classFilter, setClassFilter] = useState(null)
  const [nameFilter, setValue] = useState(null)
  const [selectedAsset, setSelectedAsset] = useState(null)

  const [reload, setReload] = useState(0)
  const forceReload = () => setReload((prev) => prev + 1)

  return (
    <PipelineContext.Provider
      value={{ classFilter, setClassFilter, nameFilter, setValue, selectedAsset, setSelectedAsset, reload, forceReload }}
    >
      {children}
    </PipelineContext.Provider>
  )
}
