import { PlusOutlined } from "@ant-design/icons"
import { Button, Card, Flex, Typography } from "antd"
import { useEffect, useState } from "react"
import { usePipeline } from "../../../hooks/usePipeline"
import { spaces } from "../../../utils/styles"
import { pipelineStatus } from "../../../utils/utils"
import { AssetNameFilter } from "../../atoms/input/AssetNameFilter/AssetNameFilter"
import { AssetClassSelect } from "../../atoms/select/AssetClassSelect/AssetClassSelect"
import { AssetClassTag } from "../../atoms/tags/AssetClassTag/AssetClassTag"
import { KanbanPane } from "../../molecules/KanbanPane"
import { DetailPipelineAssetModal } from "../../molecules/modals/DetailPipelineAssetModal/DetailPipelineAssetModal"
import { NewPipelineAssetModal } from "../../molecules/modals/NewPipelineAssetModal"
import "./Pipeline.css"

export const Pipeline = () => {
  const [newAssetModal, setNewAssetModal] = useState(false)

  const {
    assets,
    setClassFilter,
    setValue,
    selectedAsset,
    setSelectedAsset,
    forceReload,
    refreshAssets,
    reload,
    classFilter,
    nameFilter,
    isManagementTeam,
  } = usePipeline()

  useEffect(() => {
    if (!newAssetModal) forceReload()
  }, [newAssetModal])

  useEffect(() => {
    refreshAssets()
  }, [reload, classFilter, nameFilter])

  const STATUS_WIDTH = "35vw"

  const AssetList = (status) => {
    return (
      <Flex vertical gap={spaces.space1}>
        {assets
          .filter((asset) => asset.status === status)
          .map((asset) => (
            <Card className="asset-card" onClick={() => setSelectedAsset(asset)} style={{ cursor: "pointer" }}>
              <Flex style={{ justifyContent: "space-between" }}>
                <Typography.Text ellipsis>{asset.name}</Typography.Text>
                <AssetClassTag id={asset.class} />
              </Flex>
            </Card>
          ))}
      </Flex>
    )
  }

  return (
    <>
      {selectedAsset && <DetailPipelineAssetModal onClose={() => setSelectedAsset(null)} />}
      {newAssetModal && <NewPipelineAssetModal onClose={() => setNewAssetModal(false)} />}
      <Flex vertical style={{ margin: spaces.space2, gap: spaces.space2 }}>
        <Flex gap={spaces.space2}>
          <Button key="ok" type="primary" onClick={() => setNewAssetModal(true)} disabled={!isManagementTeam}>
            <PlusOutlined />
            Cadastrar novo ativo
          </Button>
          <AssetClassSelect setAssetClass={setClassFilter} defaultValue={"Todas"} />
          <AssetNameFilter setValue={setValue} />
        </Flex>
        <Flex style={{ gap: spaces.space2 }}>
          {Object.values(pipelineStatus).map((status) => (
            <KanbanPane text={status.text} description={status.description} icon={status.icon} width={STATUS_WIDTH}>
              {AssetList(status.text)}
            </KanbanPane>
          ))}
        </Flex>
      </Flex>
    </>
  )
}
