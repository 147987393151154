import { useEffect, useState } from "react"
import { tables } from "../utils/utils"
import { useSupabase } from "./useSupabase"

const aggregateCustomers = (data) => {
  const dates = {}
  const totals = {}

  const aggregated = data.reduce((acc, { strategy, customer, target, class_name, date, amount }) => {
    const groupBy = `${strategy}-${class_name}`
    if (!acc[groupBy]) acc[groupBy] = { strategy, class_name }
    acc[groupBy][customer] = target

    dates[customer] = date.substring(0, 10)

    if (!totals[customer]) totals[customer] = 0
    totals[customer] += amount

    return acc
  }, {})

  return { excel: Object.values(aggregated), dates, totals }
}

export const useIPS = ({ locale }) => {
  const supbase = useSupabase()
  const [ips, setIPS] = useState()
  const [dates, setDates] = useState()
  const [totals, setTotals] = useState()
  const [excelAllClients, setExcelAllClients] = useState()
  const [customers, setCustomers] = useState([])

  useEffect(() => {
    const fetch = async () => {
      const { data } = await supbase.from(`view_ips_${locale}`).select("*")
      const { excel, dates, totals } = aggregateCustomers(data)
      setIPS(data)
      setDates(dates)
      setExcelAllClients(excel)
      setTotals(totals)
      setCustomers(Object.keys(dates))
    }

    fetch()
  }, [])

  return { ips, customers, dates, excelAllClients, totals }
}

export const useExpectedReturn = () => {
  const [reload, setReload] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [expectedReturn, setExpectedReturn] = useState([])
  const [date, setDate] = useState()
  const [availableDates, setAvailableDates] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const supabase = useSupabase()

  const forceReload = () => setReload((prev) => prev + 1)

  const insertExpectedReturn = (data) => supabase.from(tables.FACT_EXPECTED_RETURN).insert(data).then(forceReload)

  useEffect(() => {
    if (!date && availableDates.length > 0) setDate(availableDates[0])
  }, [availableDates])

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)

      const { data: dates } = await supabase.from(tables.FACT_EXPECTED_RETURN).select("date").order("date", { ascending: false })
      setAvailableDates(Array.from(dates.reduce((acc, { date }) => acc.add(date), new Set())))

      setExpectedReturn((await supabase.from(tables.FACT_EXPECTED_RETURN).select("*").eq("date", date)).data || [])
      setIsLoading(false)
    }

    fetch()
  }, [reload, date])

  return { expectedReturn, availableDates, showModal, isLoading, date, setShowModal, setDate, insertExpectedReturn }
}
